import { MarketIntelligenceCustomDataSection } from "@/tools/market-intelligence/components/MarketIntelligenceCustomDataSection";
import { MarketIntelligenceDisclaimer } from "@/tools/market-intelligence/components/MarketIntelligenceDisclaimer";
import { MarketIntelligenceMetabasePages } from "@/tools/market-intelligence/components/MetabaseDashboardPages";
import { MarketIntelligenceMetabasePage } from "@/tools/market-intelligence/schema";
import { Tool } from "@/types/tools";
import { ApartmentOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
const {
  Paragraph,
  Title
} = Typography;
const pages: MarketIntelligenceMetabasePage[] = [{
  label: "Comparison",
  metabaseId: "34b481fd-21d8-407d-ab77-7d4b91fe210f",
  value: "comparison"
}, {
  label: "Company Overview",
  metabaseId: "755a7630-e106-408d-a206-cd20fdbff71d",
  value: "overview"
}, {
  label: "Productivity Loss",
  metabaseId: "72f797f2-5544-4fb9-b9ef-e733a87d875e",
  value: "productivityLoss"
}, {
  label: "Failure Probability",
  metabaseId: "afeb3027-83c1-4f4f-9fe4-ee1719e99d6b",
  value: "failureProbability"
}];
export const MarketIntelligenceMciToolComponent = () => {
  return <Row gutter={[0, 24]} className="mb-16" data-sentry-element="Row" data-sentry-component="MarketIntelligenceMciToolComponent" data-sentry-source-file="mci.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="mci.tsx">
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
          XDI&apos;s third party company analysis is used industry wide for assessment of climate physical risk. The
          Multi Company Intelligence analysis provides aggregated physical risk analysis on the owned and leased
          operational properties of multiple companies for investor insight.
        </Paragraph>
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
          The MCI Data pool below is a limited list of companies analysed by XDI and aggregated to 4 time periods for
          Maximum to Date Value At Risk (MVAR), Productivity Loss (PL) and Failure Probability (FP). Use the filters and
          tables below to explore the company results.
        </Paragraph>
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="mci.tsx">
        <Title level={3} style={{
        marginTop: "0"
      }} data-sentry-element="Title" data-sentry-source-file="mci.tsx">
          Multi-Company Comparison
        </Title>
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">Select multiple companies to compare using the filter</Paragraph>
        <MarketIntelligenceMetabasePages pages={pages.slice(0, 1)} data-sentry-element="MarketIntelligenceMetabasePages" data-sentry-source-file="mci.tsx" />
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="mci.tsx">
        <Title level={3} data-sentry-element="Title" data-sentry-source-file="mci.tsx">Individual Company Deep Dive</Title>
        <Paragraph strong data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">How to use this dashboard</Paragraph>
        <Paragraph style={{
        marginBottom: ".25rem"
      }} data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
          Step 1: Select a single company using the &apos;company&apos; filter
        </Paragraph>
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
          Step 2: Filter the results by selecting one or more countries using the &apos;country&apos; filter
        </Paragraph>
        <MarketIntelligenceMetabasePages pages={pages.slice(1)} data-sentry-element="MarketIntelligenceMetabasePages" data-sentry-source-file="mci.tsx" />
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="mci.tsx">
        <MarketIntelligenceCustomDataSection title="Want more detailed data on a specific company?" hubspotFormId="5b4242bc-2061-46d1-8e4f-fd564edff26f" data-sentry-element="MarketIntelligenceCustomDataSection" data-sentry-source-file="mci.tsx">
          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
            Interested in an export of this data or additional companies? Please use the MCI Data Request form to order
            datasets for a company or request additional companies added to the MCI Data pool.
          </Paragraph>
          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="mci.tsx">
            Submission of this form will create a work order for an XDI analyst to generate excel data or contact you
            about adding to the MCI Data pool.
          </Paragraph>
        </MarketIntelligenceCustomDataSection>
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="mci.tsx">
        <MarketIntelligenceDisclaimer data-sentry-element="MarketIntelligenceDisclaimer" data-sentry-source-file="mci.tsx" />
      </Col>
    </Row>;
};
export default {
  id: "mci",
  category: "marketIntelligence",
  keyPrefix: "marketIntelligence.mci",
  icon: <ApartmentOutlined />,
  render: () => <MarketIntelligenceMciToolComponent />
} as Tool;