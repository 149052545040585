import { Embed } from "@/features/embeds";
import { MarketIntelligenceMetabasePage } from "@/tools/market-intelligence/schema";
import { Col, Row, Segmented } from "antd";
import { useState } from "react";
interface MarketIntelligenceMetabasePagesProps {
  pages: MarketIntelligenceMetabasePage[];
}
export const MarketIntelligenceMetabasePages = ({
  pages
}: MarketIntelligenceMetabasePagesProps) => {
  const [selectedPage, setSelectedPage] = useState(pages[0].value);
  const page = pages.find(({
    value
  }) => value === selectedPage);
  return <Row gutter={[0, 12]} style={{
    width: "100%"
  }} data-sentry-element="Row" data-sentry-component="MarketIntelligenceMetabasePages" data-sentry-source-file="MetabaseDashboardPages.tsx">
      {pages.length > 1 && <Col span={24}>
          <Segmented block onChange={value => {
        setSelectedPage(value);
      }} options={pages} value={selectedPage} />
        </Col>}

      {page && <Col span={24}>
          <Embed key={page.value} data={{
        type: "metabaseDashboard",
        id: page.metabaseId
      }} />
        </Col>}
    </Row>;
};