import { MarketIntelligenceCustomDataSection } from "@/tools/market-intelligence/components/MarketIntelligenceCustomDataSection";
import { MarketIntelligenceDisclaimer } from "@/tools/market-intelligence/components/MarketIntelligenceDisclaimer";
import { MarketIntelligenceMetabasePages } from "@/tools/market-intelligence/components/MetabaseDashboardPages";
import { MarketIntelligenceMetabasePage } from "@/tools/market-intelligence/schema";
import { Tool } from "@/types/tools";
import { GlobalOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
const {
  Paragraph,
  Text
} = Typography;
const pages: MarketIntelligenceMetabasePage[] = [{
  label: "Country Results",
  metabaseId: "ea4a6115-f89f-4d1a-9f50-a08503d4ba15",
  value: "countryResults"
}, {
  label: "Sub-Sovereign Results",
  metabaseId: "86746454-0659-4b58-ae58-a04fe6c9f3f7",
  value: "subSovereign"
}];
export const MarketIntelligenceGdcrToolComponent = () => {
  return <Row gutter={[0, 24]} className="mb-16" data-sentry-element="Row" data-sentry-component="MarketIntelligenceGdcrToolComponent" data-sentry-source-file="gdcr.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="gdcr.tsx">
        <Text data-sentry-element="Text" data-sentry-source-file="gdcr.tsx">
          The Gross Domestic Climate Risk Dashboard can be used to visualise country and sub-sovereign (also called
          state / territory / jurisdiction) climate risk to the built environment in over 2,600 territories around the
          world. The findings underscore the importance of pricing physical climate risk in financial markets given the
          amount of capital investment represented by the assets at risk in the provinces identified, the vulnerability
          of global supply chains, and the need for climate resilience to inform investment.
        </Text>
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="gdcr.tsx">
        <Paragraph strong data-sentry-element="Paragraph" data-sentry-source-file="gdcr.tsx">How to use this dashboard</Paragraph>
        <Paragraph style={{
        marginBottom: ".25rem"
      }} data-sentry-element="Paragraph" data-sentry-source-file="gdcr.tsx">
          Step 1: Select a region using the &apos;region&apos; filter
        </Paragraph>
        <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="gdcr.tsx">Step 2: Select a company using the &apos;company&apos; filter</Paragraph>
        <MarketIntelligenceMetabasePages pages={pages} data-sentry-element="MarketIntelligenceMetabasePages" data-sentry-source-file="gdcr.tsx" />
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="gdcr.tsx">
        <MarketIntelligenceCustomDataSection title="Want more detailed data on a specific country, region or sub-sovereign area?" hubspotFormId="5b4242bc-2061-46d1-8e4f-fd564edff26f" data-sentry-element="MarketIntelligenceCustomDataSection" data-sentry-source-file="gdcr.tsx">
          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="gdcr.tsx">
            Interested in finer resolution data? Please use the GDCR Data Request form to order GDCR datasets for a
            country, sub-sovereign region, or gridded down to 1.1km2.
          </Paragraph>

          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="gdcr.tsx">
            Submission of this form will create a work order for an XDI analyst to generate data for
            state/territory/jurisdiction or whole countries using XDI&apos;s whole-of-planet physical risk analysis
            methodology.
          </Paragraph>
        </MarketIntelligenceCustomDataSection>
      </Col>

      <Col span={24} data-sentry-element="Col" data-sentry-source-file="gdcr.tsx">
        <MarketIntelligenceDisclaimer data-sentry-element="MarketIntelligenceDisclaimer" data-sentry-source-file="gdcr.tsx" />
      </Col>
    </Row>;
};
export default {
  id: "gdcr",
  category: "marketIntelligence",
  keyPrefix: "marketIntelligence.gdcr",
  icon: <GlobalOutlined />,
  render: () => <MarketIntelligenceGdcrToolComponent />
} as Tool;