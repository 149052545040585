import { HaloAcronym } from "@climaterisk/facades_ts/halo_request";
import { ArchetypeConfig } from "../../schema";
import { defaultInputValues } from "../input_values";

const { hkd } = defaultInputValues;

interface HaloArchetypeConfig extends ArchetypeConfig {
  value: HaloAcronym;
}

/**
 * Archetypes specifically used for HKMA Hub and its Halo architecture.
 */
export const defaultHaloArchetypes: HaloArchetypeConfig[] = [
  {
    id: "HRRF",
    name: "High Rise Residential Flat",
    value: "HighRiseResidentialFlatHK",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.residential,
  },
  {
    id: "LRRF",
    name: "Low Rise Residential Flat",
    value: "LowRiseResidentialFlatHK",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.residential,
  },
  {
    id: "THF",
    name: "Freestanding House HK",
    value: "FreestandingHouseHK",
    category: "residential",
    description: "",
    defaultHeightAboveGround: 0.5,
    defaultInputValues: hkd.residential,
  },
  {
    id: "HRSC",
    name: "High Rise Structure Commercial",
    value: "HighRiseStructureCommercialHK",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.commercial,
  },
  {
    id: "LRSC",
    name: "Low Rise Structure Commercial",
    value: "LowRiseStructureCommercialHK",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.37,
    defaultInputValues: hkd.commercial,
  },
  {
    id: "SSM",
    name: "Supermarket Shopping Mall",
    value: "SupermarketShoppingMallHK",
    category: "commercial",
    description: "",
    defaultHeightAboveGround: 0.19,
    defaultInputValues: hkd.commercial,
  },
];
