import { getArchetypes, getConfig } from "@/features/branding";
import { keys } from "@/tools/aggregate/portfolio-export/schema";

type HeaderRow = [string, string, string, string, string, string, string, string, string, string];
type BodyRow = [
  string, // ID
  string | undefined, // Address
  string | undefined, // Latitude
  string | undefined, // Longitude
  string | undefined, // Archetype
  number | undefined, // Build Year
  number | undefined, // Floor Height
  number | undefined, // Market Value
  number | undefined, // Replacement Cost
  number | undefined, // Mortgage Rate
];
type PortfolioExportExampleLines = [HeaderRow, ...Array<BodyRow>];

/** Example structure of a .csv which can be uploaded to Portfolio Export. */
export const buildPortfolioExportExampleCsv = (includeAutomobile: boolean): PortfolioExportExampleLines => {
  const archetypes = getArchetypes().filter(({ value }) => value !== "Automobile" || includeAutomobile);
  const {
    portfolioExport: { template },
  } = getConfig();

  const lines: PortfolioExportExampleLines = [
    [
      keys.id,
      keys.address,
      keys.latitude,
      keys.longitude,
      keys.archetype,
      keys.buildYear,
      keys.floorHeight,
      keys.marketValue,
      keys.replacementCost,
      keys.mortgageRate,
    ],
    [
      "XDI Geocode Generated LatLong from address",
      template.address,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    [
      "Customer Provided LatLong",
      undefined,
      template.centre.lat.toFixed(4),
      template.centre.lng.toFixed(4),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    [
      "Customer Provided LatLong used",
      template.address,
      template.centre.lat.toFixed(4),
      template.centre.lng.toFixed(4),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  ];

  const assetsPerRow = Math.floor(Math.sqrt(archetypes.length));

  for (const archetype of archetypes) {
    const index = lines.length;

    const latitude = template.centre.lat - 0.005 * (index % assetsPerRow);
    const longitude = template.centre.lng - 0.005 * Math.floor(index / assetsPerRow);

    lines.push([
      `Asset ${index}`,
      undefined,
      latitude.toFixed(4),
      longitude.toFixed(4),
      archetype.name,
      index > 4 ? 2020 : index > 3 ? 1990 : 1980,
      archetype.defaultHeightAboveGround || 0,
      archetype.defaultInputValues.marketValue,
      archetype.defaultInputValues.replacementCost,
      archetype.defaultInputValues.mortgageRate,
    ]);
  }

  return lines;
};

export const portfolioExportSize = {
  1000: "1000",
  20000: "20k",
  55000: "55k",
  100000: "100k",
  120000: "100k+",
};

export type PortfolioExportSizeType = keyof typeof portfolioExportSize;
